<template>
  <div
    class="base-user-search"
    :class="{ 'base-user-search--open': isOpen }"
    v-on-clickaway="closeList"
  >
    <div class="bus__inner">
      <esmp-input
        class="bus__input"
        :label="placeholder"
        error-message=""
        @focusin="isFocus = true"
        @focusout="isFocus = false"
        @input="waitCityPrint"
        v-model="query"
      />
      <div class="bus__icon" v-show="isLoad" />
    </div>

    <div class="select__list" v-if="cityList">
      <div class="select__list-inner" v-if="cityList.length">
        <div
          class="select__list-item bus__user"
          v-for="city in filteredCityList"
          :key="city.code"
          @click="selected(city)"
        >
          <span class="bus__user-name">
            {{ city.name }} {{ city.abbrev }}
          </span>
          <span
            v-if="city.isDouble"
            class="bus__user-position"
            v-html="city.districtName"
          />
        </div>
      </div>
      <div class="select__list-inner" v-else="">
        <div class="select__list-item bus__user">
          <span
            class="bus__user-name"
          >По Вашему запросу ничего не найдено
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import axios from 'axios';

const { CancelToken } = axios;
let cancel;

const SEARCH_TIMEOUT_DELAY = 100;

export default {
  name: 'DestinationSearch',
  mixins: [clickaway],
  props: {
    placeholder: {
      type: String,
      default: 'Место назначения',
    },
    searchParams: {
      type: Object,
      default: () => ({}),
    },
    searchUrl: {
      type: String,
      default: '/api/business-trip/addresses',
    },
    value: {
      type: Object,
      default: () => null,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    hideDouble: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      val: null,
      query: '',
      cityList: null,
      searchTimeoutId: null,
      isLoad: false,
      isFocus: false,
    };
  },
  computed: {
    isOpen() {
      return this.isFocus && this.cityList;
    },
    filteredCityList() {
      const value = [];

      const query = this.query.toLowerCase();

      const pushValue = (city, isDouble) => {
        // eslint-disable-next-line prefer-object-spread
        const valueItem = Object.assign({}, city);
        valueItem.isDouble = isDouble;
        value.push(valueItem);
      };

      this.cityList.forEach((city) => {
        // check name
        if (city.name.toLowerCase().includes(query)) {
          // check duplication
          const doubleIndex = value.findIndex(
            (item) => item.name === city.name && item.abbrev === city.abbrev,
          );
          if (doubleIndex !== -1) {
            if (this.hideDouble) return;
            value[doubleIndex].isDouble = true;

            pushValue(city, true);
          } else {
            pushValue(city, false);
          }
        }
      });

      return value;
    },
  },
  methods: {
    async search() {
      if (cancel) cancel();
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (this.query.trim().length >= 3) {
        this.isLoad = true;

        // const searchParams = Object.assign({}, this.searchParams, {
        //   query: this.query,
        // });

        await axios
          .get(this.searchUrl, {
            params: this.searchParams,
            cancelToken: new CancelToken((c) => {
              cancel = c;
            }),
          })
          .then((response) => {
            this.cityList = response.data;
          })
          .catch((err) => {
            if (axios.isCancel(err)) return;
            // eslint-disable-next-line consistent-return
            return err;
          })
          .finally(() => {
            this.isLoad = false;
          });
      }
    },
    waitCityPrint() {
      if (!this.isFocus) return;
      if (cancel) cancel();
      if (this.val) {
        this.val = null;
        this.$emit('input', null);
      }

      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      this.searchTimeoutId = setTimeout(this.search, SEARCH_TIMEOUT_DELAY);
    },
    selected(city) {
      this.setCity(city);
      this.$emit('input', city);
    },
    setCity(city) {
      this.val = city;
      this.cityList = null;
      if (city && city.name) {
        this.query = city.name;
      } else if (!this.isFocus) {
        this.query = '';
      }
    },
    closeList() {
      this.cityList = null;
    },
  },
  created() {
    if (this.value) this.selected(this.value);
  },
  watch: {
    value(val) {
      this.selected(val);
    },
  },
};
</script>

<style lang="scss" scoped>
$bg-gray: #f5f5f5;
$gray: #999;
$gray-light: #ddd;
$gray-dark: #666;
$black: black;
$blue: $color-primary-1-day;
$light-red: red;
$red: red;
$bg-hovered: #888;

.bus__input {
  width: 100%;
  // height: 60px;
  // padding: 0 0 0 20px;
  font-family: 'Basis Grotesque Pro', sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: $black;
  background: #fff;
  // border: 1px solid $gray-light;
  // border-radius: 4px;
  transition: border-color 0.2s ease-in-out;
  &::placeholder {
    color: $blue;
  }
}
.input--invalid {
  border-color: $light-red;
}

.base-user-search {
  position: relative;
  z-index: 4;
}
.base-user-search--open {
  z-index: 5;
}

.select__list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  width: 100%;
  max-height: 180px;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
  .select--open & {
    max-height: 300px;
  }
}
.select__list-inner {
  width: 100%;
  height: auto;
  max-height: 180px;
  overflow: auto;
  .select--open & {
    max-height: 300px;
  }
}
.select__list-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  line-height: normal;
  padding: 8px 16px;
  color: #515a6e;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: #f3f3f3;
  }
}

.bus__user {
  padding: 5px 0 5px 20px;
}
.bus__user-name {
  display: inline-block;
  margin-right: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.bus__user-additional-info {
  font-size: 12px;
  line-height: 16px;
  color: $gray;

  span + span {
    &::before {
      content: '|';
    }
  }
}

.bus__user-position {
  display: inline-block;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $blue;
}
.bus__user-email {
  display: inline-block;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $gray;
}
.bus__user-fired {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $red;
}
.bus__icon {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  img {
    display: inline-block;
    width: 100%;
    height: auto;
  }
}

.bus__label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;

  .input--hidden & {
    display: none;
  }
}
.bus__label--animated {
  position: absolute;
  top: 22px;
  left: 21px;
  margin-bottom: 0;
  font-size: 16px;
  color: $gray;
  pointer-events: none;
  transition: top 0.2s, font-size 0.2s;
}
.bus--animated-label {
  .bus__input {
    padding-top: 10px;
  }
}
.bus__input:focus ~ .bus__label--animated,
.bus__label--animated.bus__label--filled,
.bus__input:required:not(:invalid) ~ .bus__label--animated {
  top: 4px;
  font-size: 12px;
}

.bus--disabled {
  cursor: not-allowed;
  .bus__input {
    background-color: $gray;
  }
  .bus__label {
    color: white;
  }
}

.bus__input {
  font-size: 16px;
  background-color: $bg-gray;
  &::placeholder {
    font-size: 16px;
    color: $gray;
  }
}
</style>
